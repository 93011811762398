import Inputmask from "inputmask";
export default {
	mounted(el, binding, a, b) {
		Inputmask({ mask: "(+7|8) 999 999 99 99" }).mask(el);
		el.addEventListener("focus", () => {
				if (!el.value.length) {
					setTimeout(() => {
						el.setSelectionRange(3, 3);
					}, 0);
				}
			});
		el.addEventListener("blur", () => {
				if (el.value.length < 5) {
					el.value = "";
				}
			});
	},
};
