<template>
  <section class="special">
    <div class="special__conatiner container">
      <div class="special__inner">
        <div class="special__slider">
          <slider :imgs="special.slider" />
        </div>
        <div class="special__content">
          <h3 class="special__subtitle">{{ special.subtitle }}</h3>
          <h2 class="special__title">
            <div class="special__title_right">
              <img class="special__title_icon" :src="special.icon" alt="">
            </div>
            <div class="special__title_left">
              <span class="special__title_first">{{ special.model_name }}</span>
              <span class="special__title_second">{{ special.title_line }}</span>
            </div>
          </h2>
          <div class="special__clock">
            <clock class="clock__clock" :endDate="endDate" />
          </div>
          <button 
            @click="getCall({ type: 'sale', form: 'header' })"
            class="special__button button"
          >
            {{ special.btn_text }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Clock from "./components/special-clock.vue"
import Slider from "./components/special-slider.vue"
import Mixins from "@/common/mixin"
import Special from "@/common/special.js"

export default {
  components: { Clock, Slider },
  mixins: [Mixins],
  data() {
    return {
      currentIndex: 0,
      startDate: this.CONSTANTS.start_date,
      endDate: this.CONSTANTS.end_date,
    };
  },
  computed: {
    special() {
      return Special[this.currentIndex];
    },
  },
  async created() {
    const diffTime = Math.abs(new Date() - this.startDate);
    const diffDays = diffTime / (1000 * 60 * 60 * 24); 
    const index = Math.floor(diffDays / 3 % 4);

    this.currentIndex = index;
  },
};
</script>

<style lang="scss" scoped>
@use '@/styles/constants.scss' as *;

.special {
  margin-top: 55px;

  &__inner {
    background: url('../../images/bacground/special.png') no-repeat;
    display: flex;
    border-radius: 20px;
  }

  &__slider {
    width: 100%;
    height: 460px;
    max-width: 665px;
    overflow: hidden;
    margin: 40px;
    margin-right: -87px;
  }

  &__content {
    background-color: rgba(13, 74, 129, 1);
    border-radius: 20px;
    width: 100%;
    max-width: 760px;
    padding: 46px 77px 52px 159px;
  }

  &__title {
    display: flex;
    flex-direction: row;
    margin-top: 34px;
    column-gap: 18px;

    &_left {
      display: flex;
      flex-direction: column;
    }

    &_first {
      font-size: 44px;
      font-weight: 700;
      line-height: 1.2;
      color: $primary;
    }

    &_second {
      font-size: 34px;
      font-weight: 400;
      line-height: 1;
      color: $primary;
      text-transform: uppercase;
    }
  }

  &__subtitle {
    font-size: 30px;
    font-weight: 700;
    line-height: 1;
    padding: 10px 20px;
    border: 1px solid $primary;
    border-radius: 50px;
    color: $primary;
    text-align: center
  }

  &__clock {
    max-width: 600px;
    background-color: #F5F5F5;
    border-radius: 20px;
    padding: 22px 25px 7px;
    margin-top: 50px;
  }

  &__button {
    margin-top: 35px;
    width: 100%;
    text-transform: uppercase;
    color: $blue2;
    font-size: 18px;
    font-weight: 700;
    padding: 16px 14px;
  }
}

@media screen and (max-width: 1432px) {
  .special {
    &__slider {
      height: calcFontSize(290, 460, 970, 1432);
    }

    &__content {
      padding: calcFontSize(22, 46, 970, 1432) calcFontSize(22, 77, 970, 1432) 
        calcFontSize(22, 52, 970, 1432) calcFontSize(100, 159, 970, 1432);
    }

    &__title {
      margin-top: calcFontSize(16, 34, 970, 1432);

      &_icon {
        width: calcFontSize(33, 60, 970, 1432);
      }

      &_first {
        font-size: calcFontSize(20, 36, 970, 1432);
      }

      &_second {
        font-size: calcFontSize(15, 27, 970, 1432);
      }
    }

    &__subtitle {
      font-size: calcFontSize(18, 28, 970, 1432);
    }

    &__clock {
      margin-top: calcFontSize(8, 18, 970, 1432);
    }

    &__button {
      margin-top: calcFontSize(20, 35, 970, 1432);
    }
  }
}

@media screen and (max-width: 970px) {
  .special {
    border-radius: 5px;
    overflow: hidden;
    margin: 20px 10px 0px;

    &__inner {
      background: none;
      flex-direction: column;
    }

    &__slider {
      background: url('../../images/bacground/special_m.png') no-repeat;
      background-size: cover;
      margin: 0px;
      padding: 15px;
      height: auto;
      max-width: none;
      border-radius: 5px;
      position: relative;
      z-index: 2;
    }

    &__content {
      padding: 15px;
      padding-top: 25px;
      margin-top: -10px;
      border-radius: 0px;
      position: relative;
      z-index: 1;
    }

    &__title {
      margin-top: 16px;

      &_icon {
        width: 30px;
        height: 38px;
      }

      &_first {
        font-size: 24px;
      }

      &_second {
        font-size: 18px;
      }
    }

    &__subtitle {
      font-size: 14px;
    }

    &__clock {
      margin-top: 18px;
    }

    &__button {
      margin-top: 14px;
      font-size: 12px;
    }
  }
}
</style>