<template>
  <div class="main-container" :class="[device_platform]">
    <div class="block-clock-container">
      <div class="bclock-clock">
        <div :key="stat" v-for="stat in date_parts" class="bclock-clock-stat">
          <div class="bclock-clock-stat__value-container">
            <div class="bclock-clock-stat__value no-select">
              {{ timeElement(stat)[0] }}
            </div>
            <div class="bclock-clock-stat__value no-select">
              {{ timeElement(stat)[1] }}
            </div>
          </div>
          <div class="bclock-clock-stat__name no-select">
            {{ stat.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from "../../../common/mixin";
import CF from "../../../common/common-functions";

export default {
  name: "clock",
  components: {},
  directives: {},
  mixins: [Mixin],
  props: ["endDate"],
  data() {
    return {
      date_parts: [
        {
          name: "Дней",
          units: "days",
          value: 0,
          period: 0,
        },
        {
          name: "Часов",
          units: "hours",
          value: 0,
          period: 24,
        },
        {
          name: "Минут",
          units: "minutes",
          value: 0,
          period: 60,
        },
        {
          name: "Секунд",
          units: "seconds",
          value: 0,
          period: 60,
        },
      ],
    };
  },
  created() {
    let small_part_multiplier = 1,
      time_left = (this.endDate.getTime() - Date.now()) / 1000;
    for (let i = this.date_parts.length - 1; i >= 0; --i) {
      let part = this.date_parts[i];
      part.value = Math.floor(
        (time_left / small_part_multiplier) % (part.period || 10000)
      );
      part.name = this.getName(part);
      small_part_multiplier = small_part_multiplier * part.period;
    }
    // console.log(this.date_parts);
    setInterval(this.clockTick, 1000);
  },
  computed: {},
  methods: {
    clockTick() {
      for (let i = this.date_parts.length - 1; i >= 0; --i) {
        if (this.date_parts[i].value > 0) {
          --this.date_parts[i].value;
          this.date_parts[i].name = this.getName(this.date_parts[i]);
          return;
        }
        this.date_parts[i].value = this.date_parts[i].period - 1;
        this.date_parts[i].name = this.getName(this.date_parts[i]);
      }
    },
    getName(date_part) {
      return CF.getNumericalName(date_part.value, date_part.units);
    },
    timeElement(stat) {
      let time = stat.value.toString();
      if (time.length < 2) {
        time = "0" + time;
      }
      return time;
    },
  },
};
</script>

<style scoped lang="scss">

.bclock-header {
  font-size: 14px;
  text-align: center;
  margin-bottom: 12px;
}

.bclock-clock {
  display: flex;
  justify-content: center;
  border-radius: 10px;
  background: transparent;
}

.bclock-clock-stat {
  /*width: 105px;*/
  /* height: 100px; */
  margin: 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bclock-clock-stat__value-container {
  display: flex;
  flex-direction: row;
}

.bclock-clock-stat__value {
  font-size: 36px;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 13px 13px 5px;
  background: rgba(13, 74, 129, 1);
  margin-right: 2px;
  border-radius: 10px;
  margin-left: 2px;
}

.bclock-clock-stat__name {
  font-size: 20px;
//   color: $grey-dark;
  margin-top: 8px;
  color: rgba(0, 0, 0, 1);
}
@media (max-width: 468px) {
.bclock-clock-stat__value{
  padding: 7px;
}
}
@media (max-width: 400px) {
  .bclock-clock-stat__value {
    width: 26px;
    margin: 0 1.5px;
    font-size: 20px;
  }
  .bclock-clock-stat__name {
    font-size: 14px;
  }
  .bclock-clock-stat {
    margin: 0 3px;
  }
}
</style>
