<template>
  <header class="header">
    <div class="container">
      <div class="header__inner">
        <div class="header__content">
          <div class="header__content-item">
            <img class="header__content-img" src="../images/logo/rolf-logo.svg" alt="" />
          </div>
          <div class="header__content-item header__work-time">
            <p class="header__content-time work-time">
              {{CONSTANTS.worktime}}
            </p>
          </div>
<!--          <div class="header__content-item header__auto">-->
<!--            <img src="../images/logo/auto-logo.png" alt="" />-->
<!--          </div>-->
          <div class="header__content-item">
            <a @click="sendMetric('click_phone')" class="phone" :href="'tel:' + CONSTANTS.phone_raw">{{CONSTANTS.phone}}</a>
            <p class="header__phone-time work-time">
              {{CONSTANTS.worktime}}
            </p>
          </div>
          <div class="header__content-item">
            <button @click="getCall({ type: 'sale', form: 'header' })" class="button button-dark-blue">Обратный звонок</button>
          </div>
          <div class="header__content-item header__hynday">
            <img src="../images/logo/hundai-logo.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="header__content-mobile header__mobile">
      <div class="container">
        <div class="header__mobile-inner">
          <div class="header__mobile-item">
            <img src="../images/logo/rolf-logo.svg" alt="" />
          </div>
          <div @click="menu_is_open = true" class="header__mobile-burger"></div>
        </div>
      </div>
      <transition name="slide-fade">
        <div v-show="menu_is_open" class="header__mobile-content">
          <div @click="menu_is_open = false" class="header__mobile-close"></div>
          <div class="header__mobile-inf">
            <div class="header__mobile-inf-item">
              <img src="../images/logo/hundai-logo.png" alt="" />
            </div>
            <div class="header__mobile-phone">
              <a @click="sendMetric('click_phone')" class="phone" :href="'tel:' + CONSTANTS.phone_raw">{{CONSTANTS.phone}}</a>
              <p class="header__phone-time work-time">
               {{CONSTANTS.worktime}}
              </p>
            </div>
          </div>
          <transition name="menu-fade">
            <ul v-show="menu_is_open" class="header__mobile-list">
              <li v-for="mobile_nav in fullNavItems" :key="mobile_nav" @click="scrollTo(mobile_nav.scroll)" class="header__mobile-list-item">{{mobile_nav.text}}</li>
            </ul>
          </transition>
        </div>
      </transition>
    </div>
    <!-- <div class="header__nav">
      <div class="header__nav-container">
        <nav class="nav">
          <ul class="nav__list nav__list-main">
            <li v-for="nav_item in nav" :key="nav_item.text"  class="nav__list-item">
              <a @click.prevent="scrollTo(nav_item.scroll)" class="nav__link" href="">{{nav_item.text}}</a>
            </li>
          </ul>
          <p @click="scrollTo('models')" class="nav__table-text">Модельный ряд</p>
          <ul class="nav__list nav__list-additional">
            <li v-for="nav_item_block in nav_block" :key="nav_item_block.text" class="nav__list-item">
              <a @click.prevent="scrollTo(nav_item_block.scroll)" class="nav__link" href="">{{nav_item_block.text}}</a>
            </li>
          </ul>
        </nav>
      </div>
    </div> -->
  </header>
</template>
<script>
import Mixin from "../common/mixin";
import sendMetric from "../common/sendMetric";

export default {
  mixins: [Mixin],
  data() {
    return {
      menu_is_open: false,
      nav: [
        // {
        //   text: "Creta",
        //   scroll: "CRETA",
        // },
        {
          text: "Santa Fe",
          scroll: "newSANTAFE",
        },
        {
          text: "Tucson",
          scroll: "newTUCSON",
        },
        // {
        //   text: "Solaris",
        //   scroll: "SOLARIS",
        // },
        {
          text: "Sonata",
          scroll: "SONATA",
        },
        {
          text: "Staria",
          scroll: "STARIA",
        },
        {
          text: "Palisade",
          scroll: "PALISADE",
        },
         {
          text: "Elantra",
          scroll: "newELANTRA",
        },
      ],
      nav_block:[
        {
          text: "Трейд-ин",
          scroll: "tranid-in",
        },
        // {
        //   text: "Рассрочка",
        //   scroll: "benefit",
        // },
        {
          text: "Контакты",
          scroll: "contacts",
        },
      ],
      is_scrolled: 0,
    };
  },
  mounted() {
    document.addEventListener("scroll", () => {
      this.is_scrolled = window.scrollY > 20;
    });
    this.is_scrolled = window.scrollY > 20;
  },
  computed:{
    fullNavItems(){
      return [...this.nav,...this.nav_block]
    }
  },
  methods: {
    scrollTo(when) {
      this.menu_is_open = false
      document.getElementById(when).scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },
    sendMetric,
  },
};
</script>

<style lang="sass" scoped>
.slide-fade-enter-active
  transition: all 0.3s ease-in


.slide-fade-leave-active
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1)

.slide-fade-enter-from,
.slide-fade-leave-to
  transform: translateX(-100%)
//   opacity: 0




.menu-fade-enter-active
  transition: all 0.3s ease-in
//   transition-delay: 0.3s

.menu-fade-leave-active
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1)

.menu-fade-enter-from,
.menu-fade-leave-to
  transform: translateX(200%)
//   opacity: 0






.header
    position: fixed
    background-color: #fff
    left: 0
    right: 0
    z-index: 80
    top: 0

    &__mobile-content
      z-index: 80

    .mobile
      display: none
      @media (max-width: 640px)
        display: inline-block
        width: 100%
        text-align: center
        white-space: nowrap
    .no-mobile
      display: inline-block
      @media (max-width: 640px)
        display: none
    &__nav
        background-color: rgba(0, 44, 95, 1)
        padding: 11px 0px
        max-width: 100vw
        &-container
            max-width: 1432px
            padding: 0 32px
            margin: 0 auto
    &__phone
        &-time
            display: none
            margin-top: 6px
    &__mobile
        display: none
        position: relative
        &-content
            &--active
                display: block
        &-close
            position: absolute
            right: 20px
            top: 20px
            width: 30px
            height: 30px
            &::after
                position: absolute
                content: ""
                top: 50%
                left: -8%
                width: 34px
                height: 4px
                background-color: #000
                z-index: 111
                transform: rotate(313deg)
                border-radius: 4px
            &::before
                position: absolute
                content: ""
                top: 50%
                left: -8%
                width: 34px
                height: 4px
                background-color: #000
                z-index: 111
                transform: rotate(45deg)
                border-radius: 4px

        &-phone
            margin-top: 30px
        &-inf
            background-color: #fff
            display: flex
            align-items: center
            justify-content: center
            padding: 14px 0px
            flex-direction: column
        &-list
            display: flex
            align-items: center
            justify-content: center
            flex-direction: column
            background-color: rgba(0, 44, 95, 1)
            padding: 0px
            padding: 20px 0px
            list-style-type: none
            position: relative
            z-index: 99
            &-item
                margin: 7px 0px
                cursor: pointer
        &-content
            position: absolute
            top: 0px
            height: 100vh
            width: 100%
            color: #fff
    &__content
        display: flex
        align-items: center
        justify-content: space-evenly
        padding: 16px 0px

        &-img
          height: 28px
@media screen and (max-width: 1174px)
    .header
      position: static
    .header__auto
        display: none
@media screen and (max-width: 940px)
    .header__work-time
        display: none
    .header__phone-time
        display: block
@media screen and (max-width: 762px)
    .header__hynday
        display: none
    .header__content
      padding: 16px 12px
@media screen and (max-width: 596px)
    .header__content
        display: none
    .header__mobile
        display: block
    .header__mobile-inner
        display: flex
        align-items: center
        justify-content: space-between
        padding: 16px 20px
    .header__mobile-item
        width: 150px
    .header__mobile-burger
        width: 35px
        height: 3px
        background-color: #000
        border-radius: 2px
        position: relative
        cursor: pointer
        &::after
            position: absolute
            content: ""
            border-radius: 2px
            width: 35px
            height: 3px
            left: 0
            top: -8px
            background-color: #000
        &::before
            position: absolute
            content: ""
            width: 35px
            height: 3px
            border-radius: 2px
            left: 0
            bottom: -8px
            background-color: #000
    .nav
        opacity: 0
@media screen and (max-width: 1507px)
    .header__nav-container
        max-width: 1250px
@media screen and (max-width: 1504px)
    .nav__list-main
        justify-content: flex-start
        flex: 1 1 65%
    .nav__list-additional
        justify-content: flex-end
        flex: 1 1 auto
@media screen and (max-width: 825px)
    .nav__list-main
        display: none
    .nav__table-text
        color: #fff
        display: block
@media screen and (max-width: 596px)
    .header__nav
        height: 40px

.ny-slogan
  margin: 5px 0
  padding: 0 20px
  text-align: center
  width: 100%
  & span
      white-space: nowrap

  @media (max-width: 640px)
    font-size: 12px
    padding: 0 6px

</style>
