export default [
  {
    name: "Palisade",
    compl: "Family",
    engine: "2,2 дизель, 8 АТ, 4WD, 200 л.с.",
    advantage: '1 150 000',
    options: [
      'Apple car play /Android auto',
      'Электрорегулировка сиденья водителя и пассажира. Память сидений',
      'Аудиосистема с дисплеем 12,3" (Radio + RDS) Аудиосистема премиум-класса HARMAN KARDON: 12 динамиков, включая сабвуфер, внешний усилитель',
      'Легкосплавные диски 20',
      'Камера 360',
      'Электропривод двери багажника'
    ],
  },
  {
    name: "Palisade",
    compl: "Family",
    engine: "3,5 бензин, 8 АТ, 4WD, 249 л.с.",
    advantage: '1 150 000',
    options: [
      'Apple car play /Android auto',
      'Электрорегулировка сиденья водителя и пассажира. Память сидений',
      'Аудиосистема с дисплеем 12,3" (Radio + RDS) Аудиосистема премиум-класса HARMAN KARDON: 12 динамиков, включая сабвуфер, внешний усилитель',
      'Легкосплавные диски 20',
      'Камера 360',
      'Электропривод двери багажника'
    ],
  },
  {
    name: "Palisade",
    compl: "Luxe",
    engine: "2,2 дизель, 8 АТ, 4WD, 200 л.с.",
    advantage: '1 150 000',
    options: [
      'Apple car play /Android auto',
      'Двухсекционный люк с электроприводом',
      'Проекция показаний приборов на лобовое стекло',
      'Раздельные сиденья 2-го ряда, Вентиляция сидений 2-го ряда',
      'Умный круиз-контроль SCC, Система автоматического торможения при движении задним ходом (PCA-R), Интеллектуальный ассистент дистанционной парковки (RSPA), Ассистент удержания автомобиля в полосе движения LKA, Ассистент предотвращения столкновений спереди FCA, Ассистент предотвращения столкновений при повороте налево FCA-JX, Ассистент следования полосы движения LFA'
    ],
  },
  {
    name: "Palisade",
    compl: "Luxe ",
    engine: "3,5 бензин, 8 АТ, 4WD, 249 л.с.",
    advantage: '1 150 000',
    options: [
        'Apple car play /Android auto',
        'Двухсекционный люк с электроприводом',
        'Проекция показаний приборов на лобовое стекло',
        'Раздельные сиденья 2-го ряда, Вентиляция сидений 2-го ряда',
        'Умный круиз-контроль SCC, Система автоматического торможения при движении задним ходом (PCA-R), Интеллектуальный ассистент дистанционной парковки (RSPA), Ассистент удержания автомобиля в полосе движения LKA, Ассистент предотвращения столкновений спереди FCA, Ассистент предотвращения столкновений при повороте налево FCA-JX, Ассистент следования полосы движения LFA'
      ],
  },
  {
    name: "Palisade",
    compl: "Calligraphy",
    engine: "2,2 дизель, 8 АТ, 4WD, 200 л.с.",
    advantage: '1 150 000',
    options: [
      'Apple car play /Android auto',
      'Отделка сидений кожей Nappa',
      'Отделка потолка и боковых стоек замшей',
      'Диски оригинального дизайна R20 (Многоспицевые)',
      'Оттоманка для сидений водителя и пассажира',
      'Оригинальные элементы салона'
    ],
  },
  {
    name: "Palisade",
    compl: "Calligraphy",
    engine: "3,5 бензин, 8 АТ, 4WD, 249 л.с.",
    advantage: "1 150 000",
    options: [
      'Apple car play /Android auto',
      'Отделка сидений кожей Nappa',
      'Отделка потолка и боковых стоек замшей',
      'Диски оригинального дизайна R20 (Многоспицевые)',
      'Оттоманка для сидений водителя и пассажира',
      'Оригинальные элементы салона'
    ],
  },
  {
    name: "Palisade",
    compl: "Black Edition",
    engine: "2,2 дизель, 8 АТ, 4WD, 200 л.с.",
    advantage: '1 150 000',
    options: [
      'Apple car play /Android auto',
      'Версия Calligraphy с черным стайлингом',
      'Черные молдинги дверей',
      'Черная решетка радиатора',
      'Черные колесные диски многоспицевого дизайна',
      'Черный потолок натуральная замша'
    ],
  },
   {
    name: "Palisade",
    compl: "Black Edition",
    engine: "3,5 бензин, 8 АТ, 4WD, 249 л.с.",
    advantage: '1 150 000',
    options: [
      'Apple car play /Android auto',
      'Версия Calligraphy с черным стайлингом',
      'Черные молдинги дверей',
      'Черная решетка радиатора',
      'Черные колесные диски многоспицевого дизайна',
      'Черный потолок натуральная замша'
    ],
  },
  {
    name: "Staria",
    compl: "Prestige",
    engine: "3,5 бензин, 8 АТ, 4WD, 249 л.с.",
    advantage: '1 300 000',
    options: [
      'Apple car play /Android auto',
      'Кожанный салон, натуральная кожа', 
      'Электропривод сидений водителя и пассажира',
      'Светодиодные LED фары проекционного типа',
      'Подогрев руля, форсунок, зоны покоя дворников',
      'Система бесключевого доступа Smart Key и кнопка запуска двигателя',
      'Камера заднего вида + парктроники передние и задние',
      'Лекгосплавные диски 18“ с шинами 235/60R18',
    ],
  },
  {
    name: "Staria",
    compl: "Prestige",
    engine: "2,2 дизель, 8 АТ, 4WD, 177 л.с.",
    advantage: '1 300 000',
    options: [
      'Apple car play /Android auto',
      'Кожанный салон, натуральная кожа', 
      'Электропривод сидений водителя и пассажира',
      'Светодиодные LED фары проекционного типа',
      'Подогрев руля, форсунок, зоны покоя дворников',
      'Система бесключевого доступа Smart Key и кнопка запуска двигателя',
      'Камера заднего вида + парктроники передние и задние',
      'Лекгосплавные диски 18“ с шинами 235/60R18',
    ],
  },
  {
    name: "Staria",
    compl: "Premium",
    engine: "3,5 бензин, 8 АТ, 4WD, 249 л.с.",
    advantage: '1 300 000',
    options: [
      'Раздельные VIP-сиденья 2-го ряда с оттоманками и функцией релаксации',
      'Вентиляция всех сидений',
      'Отделка сидений кожей Nappa, потолка и стоек замшей',
      'Цифровая панель приборов с цветным экраном 10.25',
      'Навигационная система4 с сенсорным экраном 10.25',
      'Адаптивный круиз-контроль (SCC) с ассистентом движения в пробке (LVDA)',
      'Остекление с дополнительной шумоизоляцией (лобовое и передние боковые стекла)',
      'Аудиосистема премиум-класса Bose: 12 динамиков, вкл. сабвуфер и внешний усилитель',
      'На выбор 4 цвета интерьера, включая 2 уникальных: светло-серый и коричневый'
    ],
  },
  {
    name: "Staria",
    compl: "Premium",
    engine: "2,2 дизель, 8 АТ, 4WD, 177 л.с.",
    advantage: '1 300 000',
    options: [
      'Раздельные VIP-сиденья 2-го ряда с оттоманками и функцией релаксации',
      'Вентиляция всех сидений',
      'Отделка сидений кожей Nappa, потолка и стоек замшей',
      'Цифровая панель приборов с цветным экраном 10.25',
      'Навигационная система4 с сенсорным экраном 10.25',
      'Адаптивный круиз-контроль (SCC) с ассистентом движения в пробке (LVDA)',
      'Остекление с дополнительной шумоизоляцией (лобовое и передние боковые стекла)',
      'Аудиосистема премиум-класса Bose: 12 динамиков, вкл. сабвуфер и внешний усилитель',
      'На выбор 4 цвета интерьера, включая 2 уникальных: светло-серый и коричневый'
    ],
  },
  {
    name: "Santa Fe",
    compl: "Travel",
    engine: "2,5 бензин, 6 АТ, 4WD, 180 л.с.",
    advantage: '930 000',
    options: [
      'Apple car play /Android auto',
      'Кожанный салон, натуральная кожа',
      'Электропривод сидений водителя и пассажира',
      'Светодиодные LED фары проекционного типа',
      'Подогрев руля, форсунок, зоны покоя дворников',
      'Система бесключевого доступа Smart Key и кнопка запуска двигателя',
      'Камера заднего вида + парктроники передние и задние',
      'Лекгосплавные диски 18“ с шинами 235/60R18'
    ],
  },
  {
    name: "Santa Fe",
    compl: "Prime",
    engine: "2,5 бензин, 6 АТ, 4WD, 180 л.с.",
    advantage: '930 000',
    options: [
      'Панель приборов SuperVision с LCD монитором 12,3“ + Rheostat',
      'Боковые механические шторки для пассажиров заднего ряда',
      'Вентиляция передних сидений',
      'Электропривод двери багажника',
      'Лекгосплавные диски 19“ с шинами 235/55R19',
      'Задние амортизаторы с функцией самовыравнивания'
    ]
  },
  {
    name: "Santa Fe",
    compl: "Prime",
    engine: "2,2 дизель, 8 DCT, 4WD, 199 л.с.",
    advantage: '930 000',
    options: [
      'Панель приборов SuperVision с LCD монитором 12,3“ + Rheostat',
      'Боковые механические шторки для пассажиров заднего ряда',
      'Вентиляция передних сидений',
      'Электропривод двери багажника',
      'Лекгосплавные диски 19“ с шинами 235/55R19',
      'Задние амортизаторы с функцией самовыравнивания'
    ]
  },
  {
    name: "Santa Fe",
    compl: "High-tech",
    engine: "2,5 бензин, 6 А, 4WD, 180 л.с.",
    advantage: '930 000',
    options: [
      'Панорамная крыша с люком',
      'Память сиденья водителя, Электрорегулировка подколенного и поясничного  подпоров сиденья водителя',
      'Лекгосплавные диски 20“ с шинами 255/45R20',
      '360 Система камер кругового обзора, слепых зон',
      'Аудиосистема премиум Harman/Kardon,Apple car play /Android auto',
      'Легкосплавные диски 19',
    ],
  },
  {
    name: "Santa Fe",
    compl: "High-tech",
    engine: "3,5 бензин, 8 АТ, 4WD, 249 л.с.",
    advantage: '930 000',
    options: [
      'Панорамная крыша с люком',
      'Память сиденья водителя, Электрорегулировка подколенного и поясничного  подпоров сиденья водителя',
      'Лекгосплавные диски 20“ с шинами 255/45R20',
      '360 Система камер кругового обзора, слепых зон',
      'Аудиосистема премиум Harman/Kardon,Apple car play /Android auto',
      'Легкосплавные диски 19',
    ],
  },
  {
    name: "Santa Fe",
    compl: "High-tech",
    engine: "2,2 дизель, 8 DCT, 4WD, 199 л.с.",
    advantage: '930 000',
    options: [
      'Панорамная крыша с люком',
      'Память сиденья водителя, Электрорегулировка подколенного и поясничного  подпоров сиденья водителя',
      'Лекгосплавные диски 20“ с шинами 255/45R20',
      '360 Система камер кругового обзора, слепых зон',
      'Аудиосистема премиум Harman/Kardon,Apple car play /Android auto',
      'Легкосплавные диски 19',
    ],
  },
  {
    name: "Santa Fe",
    compl: "Luxe",
    engine: "3,5 бензин, 8 АТ, 4WD, 249 л.с.",
    advantage: '930 000',
    options: [
      'Дополнение к версии High-tech',
      'Проекция показаний приборов на лобовое стекло',
      'Умный круиз-контроль SCC',
      'Ассистент предотвращения столкновений спереди FCA',
      'Ассистент предотвращения столкновений при повороте налево FCA-JX'
    ],
  },
  {
    name: "Santa Fe",
    compl: "Luxe",
    engine: "2,2 дизель, 8 DCT, 4WD, 199 л.с",
    advantage: '930 000',
    options: [
      'Дополнение к версии High-tech',
      'Проекция показаний приборов на лобовое стекло',
      'Умный круиз-контроль SCC',
      'Ассистент предотвращения столкновений спереди FCA',
      'Ассистент предотвращения столкновений при повороте налево FCA-JX'
    ],
  },
  {
    name: "Santa Fe",
    compl: "Luxe",
    engine: "3,5 бензин, 8 АТ, 4WD, 249 л.с.",
    advantage: '930 000',
    options: [
      'Дополнение к версии High-tech',
      'Проекция показаний приборов на лобовое стекло',
      'Умный круиз-контроль SCC',
      'Ассистент предотвращения столкновений спереди FCA',
      'Ассистент предотвращения столкновений при повороте налево FCA-JX'
    ],
  },
  {
    name: "Santa Fe",
    compl: "Luxe",
    engine: "2,2 дизель, 8 DCT, 4WD, 199 л.с.",
    advantage: '930 000',
    options: [
      'Дополнение к версии High-tech',
      'Проекция показаний приборов на лобовое стекло',
      'Умный круиз-контроль SCC',
      'Ассистент предотвращения столкновений спереди FCA',
      'Ассистент предотвращения столкновений при повороте налево FCA-JX'
    ],
  },
  {
    name: "Elantra NEW",
    compl: "Luxe",
    engine: "1,5л, 1,5  CVT, 2WD, 115 л.с.",
    advantage: '475 000',
    options: [
      'Панорамный люк в крыше',
      'Легкосплавные диски 17',
      'Кожанная отделка салона',
      'Светодиодные фары ближнего и дальнего света',
      'Мультимедийная система с цветным 12" экраном',
      'Камера заднего вида, Передний и задний парктроник'
    ],
  },
  {
    name: "Elantra NEW",
    compl: "Exclusive Edition",
    engine: "1,5л, 1,5  CVT, 2WD, 115 л.с.",
    advantage: '475 000',
    options: [
      'В дополнение к версии Luxe',
      'Двухзонный климат контроль',
      'Подогрев руля и сидений'
    ],
  },
  {
    name: "Elantra NEW",
    compl: "Elegance",
    engine: "1,6 бензин, 6 АТ, 2WD, 128 л.с.",
    advantage: '475 000',
    options: [
      'Навигационная система, премиальная аудиосистема Bose',
      'Кожа в отделке сидений',
      'Система контроля слепых зон',
      'Электропривод и память настроек сиденья водителя',
      'Светодиодная подсветка передней панели'
    ],
  },
  {
    name: "Elantra NEW",
    compl: "Prestige",
    engine: "1,6 бензин, 6 АТ, 2WD, 128 л.с.",
    advantage: '475 000',
    options: [
      'Электрорегулировки сиденья переднего пассажира',
      'Уникальный цвет салона',
      'Легкосплавные диски 17',
      'Память настроек сиденья водителя (для двух водителей: сиденье, внешние зеркала)'
    ],
  },
  {
    name: "Elantra NEW",
    compl: "Active",
    engine: "1,6 бензин, 6 АТ, 2WD, 128 л.с.",
    advantage: '475 000',
    options: [
      'Двухзонный климат-контроль с функцией антизапотевания лобового стекла',
      'Светодиодные фары ближнего и дальнего света проекционного типа',
      'Задние датчики парковки',
      'Круиз-контроль с управлением на руле',
      'Легкосплавные диски 16" с шинами 205/55 R16"'
    ],
  },
  {
    name: "Tucson",
    compl: "Comfort",
    engine: "2,0 бензин, 6 АТ, 2WD, 149 л.с.",
    advantage: '830 000',
    options: [
      'Лекгосплавные диски 17" с шинами 235/65R17',
      'Подогрев руля, форсунок, зоны покоя дворников',
      'Аудиосистема с дисплеем 8"',
      'Apple Carplay / Android auto + Камера заднего вида',
      'Камера заднего вида, задние парктроники',
      'Безключевой доступ в салон и запуск с кнопки'
    ]
  },
  {
    name: "Tucson",
    compl: "Comfort",
    engine: "2,0 бензин, 6 АТ, 4WD, 149 л.с.",
    advantage: '830 000',
    options: [
      'Лекгосплавные диски 17" с шинами 235/65R17',
      'Подогрев руля, форсунок, зоны покоя дворников',
      'Аудиосистема с дисплеем 8"',
      'Apple Carplay / Android auto + Камера заднего вида',
      'Камера заднего вида, задние парктроники',
      'Безключевой доступ в салон и запуск с кнопки'
    ]
  },
  {
    name: "Tucson",
    compl: "Travel",
    engine: "2,0 бензин, 6 АТ, 2WD, 149 л.с.",
    advantage: '830 000',
    options: [
      'Кожанный салон, натуральная кожа',
      'Электропривод сиденья',
      'Электронный стояночный тормоз EPB + AutoHold',
      'Светодиодные LED фары проекционного типа',
      'Парктроник передний и задний',
      'Лекгосплавные диски 18" с шинами 235/60R18"'
    ],
  },
  {
    name: "Tucson",
    compl: "Travel",
    engine: "2,0 бензин, 6 АТ, 4WD, 149 л.с.",
    advantage: '830 000',
    options: [
      'Кожанный салон, натуральная кожа',
      'Электропривод сиденья',
      'Электронный стояночный тормоз EPB + AutoHold',
      'Светодиодные LED фары проекционного типа',
      'Парктроник передний и задний',
      'Лекгосплавные диски 18" с шинами 235/60R18"'
    ],
  },
  {
    name: "Tucson",
    compl: "Travel",
    engine: "2,5 бензин, 6 АТ, 4WD, 149 л.с.",
    advantage: '830 000',
    options: [
      'Кожанный салон, натуральная кожа',
      'Электропривод сиденья',
      'Электронный стояночный тормоз EPB + AutoHold',
      'Светодиодные LED фары проекционного типа',
      'Парктроник передний и задний',
      'Лекгосплавные диски 18" с шинами 235/60R18"'
    ],
  },
  {
    name: "Tucson",
    compl: "High-tech",
    engine: "2,0 бензин, 6 АТ, 4WD, 149 л.с.",
    advantage: '830 000',
    options: [
      'Цифровая приборная панель',
      'Навигационная ситема с экраном 10.25", аудиосистема премиум класса',
      'Премиальная аудиосистема KRELL',
      'Память для сиденья водителя',
      'Вентиляция передних сидений',
      'Легкосплавные диски 19"'
    ],
  },
  {
    name: "Tucson",
    compl: "High-tech",
    engine: "2,5 бензин, 6 АТ, 4WD, 149 л.с.",
    advantage: '830 000',
    options: [
      'Цифровая приборная панель',
      'Навигационная ситема с экраном 10.25", аудиосистема премиум класса',
      'Премиальная аудиосистема KRELL',
      'Память для сиденья водителя',
      'Вентиляция передних сидений',
      'Легкосплавные диски 19"'
    ],
  },
  {
    name: "Tucson",
    compl: "Luxe",
    engine: "2,5 бензин, 6 АТ, 4WD, 149 л.с.",
    advantage: '830 000',
    options: [
      'Панорамная крыша с люком',
      'Система кругового обзора SVM',
      'Система обзора слепых зон BVM',
      'Светодиодное LED освещение салона'
    ],
  },
]
